import axios from "axios";
import SPARTA_TIME from "../components/common/student_week_schedule/js/sparta_time";
import SmsApiService from "@/services/smsapi.service";
import ApiService from "@/services/api.service";
import jwtService from "./jwt.service";
import { ROLE } from "@/js/util/common";
import { WEEKDAYS } from "@/components/common/student_week_schedule/js/constant";
import { ApiReq } from "@/components/manager/ManagerUtils";
import { date_format } from "@/js/util/math";

const ID_EX_SETTING = "id_ex_setting";
const EXAPI = {
	version: "202408240001",
	async CallTest(command, params) {
		const header = {
			"Content-Type": "application/json",
			Accept: "application/json",
		};

		const ax = axios.create({
			baseURL: "/exapi/",
			headers: header,
		});

		var r;
		try {
			const req = {
				command: command,
				params: params,
			};

			r = await ax.post("test.php", req);
			// console.log(r);
		} catch (e) {
			r = null;
		}

		return r;
	},

	async CheckVersion() {
		const req = {};
		var r = (await this.Call("get_version", req)).data;
		const version = r.current_version;

		// console.log(this.version, version);

		if (Number(this.version) < Number(version)) {
			// console.log("need to update");
			return false; // need to update
		}

		return true;
	},

	GetCenterNo() {
		const user_info = jwtService.getUserInfo();
		if (user_info?.is_admin) {
			// if(user_info.role == ROLE.COMPANYADMIN)
			return Number(jwtService.getCompanyAdminCenterInfo()?.no);
		}

		return Number(user_info.center_no);
	},

	ApiBaseUrl: "/exapi/",
	async Call(command, params) {
		const header = {
			"Content-Type": "application/json",
			Accept: "application/json",
		};

		const ax = axios.create({
			baseURL: this.ApiBaseUrl,
			headers: header,
		});

		var r;
		try {
			const req = {
				command: command,
				params: params,
			};

			r = await ax.post("api.php", req);
			// console.log(r);
		} catch (e) {
			r = null;
		}

		return r;
	},
};

const EXSETTING = {
	async LoadExSetting() {
		const ax = axios.create({
			baseURL: EXAPI.ApiBaseUrl,
		});

		var r = await ax.post("ex_setting.json");
		var r_j = JSON.stringify(r.data);
		window.localStorage.setItem(ID_EX_SETTING, r_j);
	},

	async SaveExSetting(setting) {
		const req = {
			setting: setting,
		};

		return await EXAPI.Call("save_setting", req);
	},

	ExSetting: () => JSON.parse(window.localStorage.getItem(ID_EX_SETTING)),
};

const SCHEDULE_API = {
	STAY_STATUS: {
		NOTHING: -1,
		STAY: 1,
		OUT: 2,
	},
	ACTION_TYPE: {
		NOTHING: -1,
		LOGIN: 10,
		LOGOUT: 11,
		CHECKIN: 12,
		CHECKOUT: 13,
		GOOUT: 14,
		COMEBACK: 15,

		SENDSMS: 101,
		PANELTY: 10000,
		PANELTY_UNDER30: 10030,
		PANELTY_UPPER30: 10031,
		PANELTY_OVERHOUR: 10032,
		PANELTY_OVER2HOUR: 10033,
		PANELTY_OVER3HOUR: 10034,
		PANELTY_ABSENT: 10050,

		PLANNER_START: 200,
		PLANNER_FINISH: 201,
		PLANNER_WEEK: 202,
		PLANNER_MODIFY: 203,
		PLANNER_DELETE: 204,

		GetString(index) {
			index = Number(index);
			switch (index) {
				case this.LOGIN:
					return "로그인";
				case this.LOGOUT:
					return "로그아웃";
				case this.CHECKIN:
					return "입실";
				case this.CHECKOUT:
					return "퇴실";
				case this.GOOUT:
					return "외출";
				case this.COMEBACK:
					return "복귀";
				case this.PANELTY:
					return "벌점";
				case this.PANELTY_UNDER30:
					return "30분 이내 지각";
				case this.PANELTY_UPPER30:
					return "30분 초과 지각";
				case this.PANELTY_OVERHOUR:
					return "1시간 초과 지각";
				case this.PANELTY_OVER2HOUR:
					return "2시간 초과 지각";
				case this.PANELTY_OVER3HOUR:
					return "3시간 초과 지각";
				case this.PANELTY_ABSENT:
					return "결석";
			}
		},

		GetCommand(index) {
			index = Number(index);
			switch (index) {
				case this.LOGIN:
					return "login";
				case this.LOGOUT:
					return "logout";
				case this.CHECKIN:
					return "checkin";
				case this.CHECKOUT:
					return "checkout";
				case this.GOOUT:
					return "goout";
				case this.COMEBACK:
					return "comeback";
				default:
					return "";
			}
		},
	},

	async save_schedule(student_no, json, date_target_uts) {
		var req = {
			command: "save_schedule",
			params: {
				student_no: student_no,
				json: json,
			},
		};

		if (date_target_uts != undefined)
			req.params.date_target_uts = date_target_uts;

		var res = await EXAPI.Call("save_schedule", req.params);

		// console.log(res);

		return res.data;
	},

	async get_temporal_schedule_list(start_uts, end_uts) {
		const req = {
			center_no: jwtService.getUserInfo().center_no,
			start_uts: start_uts,
			end_uts: end_uts,
		};

		return (await EXAPI.Call("get_temporal_schedule_list", req)).data;
	},

	async request_temporal_schedule_week(
		student_id,
		schedule_data,
		reason,
		date_target_uts = 0
	) {
		var week_index = -1;
		if (date_target_uts) date_target_uts += 60 * 60 * 12; // 오후 12시 기준
		const req = {
			member_id: student_id,
			target_date_uts: date_target_uts,
			week_index: week_index,
			schedule_data: schedule_data,
			reason: reason,
		};

		return (await EXAPI.Call("request_temporal_schedule", req)).data;
	},

	async request_temporal_schedule_oneday(
		student_id,
		schedule_data,
		reason,
		target_date,
		confirmer_id = ""
	) {
		var week_index = WEEKDAYS.GetWeekIndex(new Date(target_date));
		var target_date_uts =
			Math.floor(new Date(target_date).getTime() / 1000) + 60 * 60 * 12; // 오후 12시 기준

		const req = {
			member_id: student_id,
			target_date_uts: target_date_uts,
			week_index: week_index,
			schedule_data: schedule_data,
			reason: reason,
			confirmer_id: confirmer_id,
		};

		return (await EXAPI.Call("request_temporal_schedule", req)).data;
	},

	async confirm_temporal_schedule(
		no,
		status = 1 // no : temporal schedule no
	) {
		/*
        status : 0 - request, 1 - accept, 2 - reject, 9 - deleted,
        */
		const req = {
			no: no,
			confirmer_id: jwtService.getUserInfo().userid,
			status: status,
		};

		return (await EXAPI.Call("confirm_temporal_schedule", req)).data;
	},

	async getUserInfo() {
		var user_info = jwtService.getUserInfo();
		var req = {
			id: user_info.userid,
		};

		var no = (await EXAPI.Call("get_user_no_by_id", req)).data.no;
		user_info.no = no;

		return user_info;
	},

	async cancel_today_schedule(student_id, reason, target_date = null) {
		if (target_date == null) target_date = new Date().setHours(0, 0, 0);

		var week_index = WEEKDAYS.GetWeekIndex(new Date(target_date));
		var target_date_uts =
			Math.floor(new Date(target_date).getTime() / 1000) + 60 * 60 * 12; // 오후 12시 기준
		var confirmer_id = jwtService.getUserInfo().userid;
		let schedule_data = JSON.stringify({
			sections: [],
			memo: "(입실취소) - " + reason,
		});
		const req = {
			member_id: student_id,
			target_date_uts: target_date_uts,
			week_index: week_index,
			reason: reason,
			confirmer_id: confirmer_id,
			schedule_data: schedule_data,
		};

		return (await EXAPI.Call("cancel_schedule_temporal", req)).data;
	},

	async load_schedule_ex(
		student_no,
		with_temporal = true,
		uts_temporal = null,
		index = 0
	) {
		if (!uts_temporal)
			uts_temporal = Math.floor(new Date().getTime() / 1000);
		const req = {
			student_no: student_no,
			with_temporal: with_temporal,
			start_index: index,
			uts_temporal,
		};

		var res = (await EXAPI.Call("load_schedule_ex", req)).data;

		console.log(res);
		var data = {
			schedule: res.schedule,
			total_mins: this.get_mins_week(
				JSON.parse(res.schedule.json).map((x) => x.sections)
			),
			count: res.count,
			index: res.index,
		};
		return data;
	},

	async delete_schedule(schedule_no) {
		var data = await EXAPI.Call("delete_schedule", { no: schedule_no });
		return data;
	},

	async get_schedule_list(student_no, start_index, length) {
		const req = {
			student_no: student_no,
			start_index: Number(start_index),
			length: Number(length),
		};

		var data = (await EXAPI.Call("get_schedule_list", req)).data;
		return data;
	},

	async load_schedule(student_no, with_temporal = true, uts_temporal = null) {
		if (!uts_temporal)
			uts_temporal = Math.floor(new Date().getTime() / 1000);
		const req = {
			student_no: student_no,
			with_temporal: with_temporal,
			uts_temporal,
		};

		var data = (await EXAPI.Call("load_schedule", req)).data;
		data.total_mins = this.get_mins_week(
			JSON.parse(data.json).map((x) => x.sections)
		);
		return data;
	},

	async load_all_student_schedule(center_no = null) {
		if (!center_no) center_no = jwtService.getUserInfo().center_no;
		const req = {
			center_no: center_no,
		};
		let ret = (await EXAPI.Call("load_all_student_schedule", req)).data;
		return ret;
	},
	get_mins_section(section) {
		const mins = section.stay.end.minstamp - section.stay.start.minstamp;
		return mins;
	},
	get_mins_day(day_sections) {
		var total_mins = 0;
		day_sections.forEach((item) => {
			total_mins += this.get_mins_section(item);
		});

		return total_mins;
	},
	get_mins_week(week_sections) {
		var total_mins = 0;
		week_sections.forEach((item) => {
			total_mins += this.get_mins_day(item);
		});

		return total_mins;
	},

	async add_pin(student_no, jts_pin, pin_type) {
		const req = {
			student_no: student_no,
			uts: Math.floor(jts_pin.getTime() / 1000),
			pin_type: pin_type,
		};
		const res = await EXAPI.Call("add_pin", req);
		return res;
	},

	async pin_exist(student_no, section_info, action_type) {
		const req = {
			student_no: student_no,
			section_uuid: section_info?.section.uuid,
			pin_type: action_type,
		};

		return await EXAPI.Call("pin_exist", req);
	},

	async pin_exist_late(student_no, section_info) {
		const req = {
			student_no: student_no,
			section_uuid: section_info?.section.uuid,
		};

		return await EXAPI.Call("pin_exist_late", req);
	},

	async get_section(student_no, jts_now) {
		const req = {
			student_no: student_no,
			uts: Math.floor(jts_now.getTime() / 1000),
		};

		return await EXAPI.Call("get_section", req);
	},

	async add_action_log(student_no, jts_now, action_type) {
		if (jts_now == undefined) jts_now = new Date();
		const req = {
			student_no: student_no,
			uts: Math.floor(jts_now.getTime() / 1000),
		};
		let command = SCHEDULE_API.ACTION_TYPE.GetCommand(action_type);

		console.log(student_no, jts_now, action_type, command);
		if (!command) {
			return;
		}
		const res = await EXAPI.Call(command, req);
		return res;
	},

	async get_last_status(student_no, jts_start, jts_end) {
		if (jts_end == undefined || jts_start == undefined) {
			var now = new Date();
			now.setHours(0, 0, 0);
			jts_start = now.getTime() + 1000 * 60 * 60 * 5;
			jts_end = now.getTime() + 1000 * 60 * 60 * 27;
		}

		const req = {
			student_no: student_no,
			uts_start: Math.floor(jts_start / 1000),
			uts_end: Math.floor(jts_end / 1000),
		};

		return await EXAPI.Call("get_last_status", req);
	},

	async get_last_status_section(student_no, sections, jts_now) {
		if (jts_now == undefined) jts_now = new Date();

		var pin = SPARTA_TIME.FindSectionByTimestamp(sections, jts_now);
		console.log(pin);
		if (pin.section_index != -1) {
			var r = await SCHEDULE_API.get_last_status(
				student_no,
				pin.timeinfo.jts_start,
				pin.timeinfo.jts_end
			);
			pin.status = r.data.status;
			return pin;
		}

		throw pin;
	},

	async get_pin_list(student_no, jts_start, jts_end) {
		const req = {
			student_no: student_no,
			uts_start: Math.floor(jts_start / 1000),
			uts_end: Math.floor(jts_end / 1000),
		};

		return await EXAPI.Call("get_pin_list", req);
	},

	async get_pin_list_section(student_no, section, t) {
		if (t == undefined) t = new Date();
		var stay = section.stay;
		const jts_start = SPARTA_TIME.MinstampToTimestamp(
			t,
			stay.start.minstamp - this.SECTION_MARGIN
		).getTime();
		const jts_end = SPARTA_TIME.MinstampToTimestamp(
			t,
			stay.end.minstamp + this.SECTION_MARGIN
		).getTime();
	},

	async action_login(student_info, date) {
		const student_no = student_info.no;
		if (date == undefined) date = new Date();
		const login_res = await SCHEDULE_API.add_action_log(
			student_no,
			date,
			this.ACTION_TYPE.LOGIN
		);
		const login_info = login_res.data;
		console.log(login_info, login_res);
		const last_status = Number(login_info.last_status);
		const first_checkin_check =
			last_status == SCHEDULE_API.ACTION_TYPE.CHECKOUT ||
			last_status == SCHEDULE_API.ACTION_TYPE.NOTHING;
		const comeback_checkin_check =
			last_status == SCHEDULE_API.ACTION_TYPE.GOOUT;

		const section_res = await SCHEDULE_API.get_section(student_no, date);
		let section_info = section_res.data;

		if (section_info && section_info.section !== -1) {
			let cur_minstamp = SPARTA_TIME.GetSpartaMinStampValue(date);
			// 24시간 지난 이후 지각 판단.
			if (cur_minstamp < (SPARTA_TIME.END_HOUR + 1) * 60) {
				cur_minstamp += 24 * 60;
			}
			const cur_24time = SPARTA_TIME.MinstampTo24(cur_minstamp);
			const enter_minstamp = Number(section_info.section.start.minstamp);
			const enter_24time = section_info.section.start.str;

			// 지각 확인.
			let late_type = SCHEDULE_API.ACTION_TYPE.NOTHING;
			if (cur_minstamp >= enter_minstamp + 180) {
				// 3시간 이상 지각
				late_type = SCHEDULE_API.ACTION_TYPE.PANELTY_OVER3HOUR;
			} else if (cur_minstamp >= enter_minstamp + 120) {
				// 2시간 이상 지각
				late_type = SCHEDULE_API.ACTION_TYPE.PANELTY_OVER2HOUR;
			} else if (cur_minstamp >= enter_minstamp + 60) {
				// 1시간 이상 지각
				late_type = SCHEDULE_API.ACTION_TYPE.PANELTY_OVERHOUR;
			} else if (cur_minstamp >= enter_minstamp + 30) {
				// 30분 이상 지각
				late_type = SCHEDULE_API.ACTION_TYPE.PANELTY_UPPER30;
				//} else if ((cur_minstamp >= enter_minstamp + 10 && first_checkin_check) || (cur_minstamp >= enter_minstamp + 5 && comeback_checkin_check)) {
			} else if (cur_minstamp > enter_minstamp) {
				// 30분 이내 지각
				late_type = SCHEDULE_API.ACTION_TYPE.PANELTY_UNDER30;
			}
			const action_type = [
				this.ACTION_TYPE.CHECKIN,
				this.ACTION_TYPE.COMEBACK,
				this.ACTION_TYPE.PANELTY_UPPER30,
				this.ACTION_TYPE.PANELTY_UNDER30,
				this.ACTION_TYPE.PANELTY_OVERHOUR,
				this.ACTION_TYPE.PANELTY_OVER2HOUR,
				this.ACTION_TYPE.PANELTY_OVER3HOUR,
			];
			const pin_exist_res = await SCHEDULE_API.pin_exist(
				student_no,
				section_info,
				action_type
			);
			let pin_exist = pin_exist_res.data;

			if (
				late_type !== SCHEDULE_API.ACTION_TYPE.NOTHING &&
				pin_exist &&
				pin_exist.exist === false
			) {
				section_info.late_info = {
					type: late_type,
					data: {
						schedule_time: enter_24time,
						actual_time: cur_24time,
					},
				};
			}
		}

		const smssetting = EXSETTING.ExSetting();
		
		if ((smssetting.sms?.send_time & 1) > 0) {
			// 로그인 시점 문자가 필요한 경우 여기서 처리.
			let type_text = "[cancel]";

			if (first_checkin_check) {
				// 퇴실 혹은 초기 상태 일 경우 입실
				type_text = "입실";
			} else if (comeback_checkin_check) {
				// 외출 상태 일 경우 복귀
				type_text = "복귀";
			}

			// 입실 문자 발송
			if (
				typeof student_info.phone == "string" &&
				student_info.phone.replace(/-/g, "")
			) {
				console.log("입실 문자 발송");
				SCHEDULE_API.send_sms_checkinout(
					student_info,
					"checkin",
					type_text
				);
			} else {
				console.log("부모님 번호 없음.");
			}
		}

		// 2024-04-17 로그인 시점 만기일이 지난 학생에게 만기안내문자 재전송
		const expiration_datetime = date_format(student_info.expiration_datetime,"YYYY-MM-DD");
		const today = date_format(SPARTA_TIME.GetNowDate(), "YYYY-MM-DD")
		if(expiration_datetime < today)
		{
			let text = smssetting.expiration?.message;
			if(text) {
				const center_phone =
				student_info.center_phone_number_sms &&
				student_info.center_phone_number_sms.trim().replace(/-/g, "")
					? student_info.center_phone_number_sms.trim().replace(/-/g, "")
					: null;
				text = text.replace("{{center_name}}", student_info.center_name);
				text = text.replace("{{student_name}}", student_info.name);
				text = text.replace("{{expiration_date}}", expiration_datetime);
				var message = {
					to: student_info.phone.replace(/-/g, ""),
					from: center_phone ?? "01058283551",
					text: text,
				};

				var messages = [message];
				SmsApiService.send_messages(messages);
			}
		}

		return {
			login_info: login_info,
			section_info: section_info,
		};
	},

	async send_sms_checkinout(student_info, type, type_text) {
		var setting = EXSETTING.ExSetting();
		if (!setting.sms) {
			console.log("세팅이 설정되지 않음.");
			return;
		}

		var text = setting.sms.message.checkin;

		if (type_text == "[cancel]") {
			console.log("문자 발송 중지됨 : " + type_text);
			return;
		}
		if (type == "checkout") {
			if (type_text == undefined) type_text = "퇴실";
			text = setting.sms.message.checkout;
		} else {
			if (type_text == undefined) type_text = "입실";
		}
		var center_phone =
			student_info.center_phone_number_sms &&
			student_info.center_phone_number_sms.trim().replace(/-/g, "")
				? student_info.center_phone_number_sms.trim().replace(/-/g, "")
				: null;
		var curent_time_hourmin = date_format(new Date(), "HH시 mm분");

		text = text.replace("{{student_name}}", student_info.name);
		text = text.replace("{{action_name}}", type_text);
		text = text.replace("{{center_name}}", student_info.center_name);
		text = text.replace("{{curent_time_hourmin}}", curent_time_hourmin);
		text = text.replace(
			"{{student_pure_name}}",
			student_info.name.replace(/[0-9]| |\(.*?\)/g, "")
		);
		var message = {
			to: student_info.phone.replace(/-/g, ""),
			from: center_phone ?? "01058283551",
			text: text,
		};

		var messages = [];
		messages.push(message);
		var res = await SmsApiService.send_messages(messages);
		console.log(res);
	},

	async action_checkin(student_info, date, status, type_text) {
		const student_no = student_info.no;

		if (date == undefined) date = new Date();

		await SCHEDULE_API.add_action_log(student_no, date, status);

		if ((EXSETTING.ExSetting().sms?.send_time & 2) > 0) {
			if (
				status === SCHEDULE_API.ACTION_TYPE.CHECKIN ||
				status === SCHEDULE_API.ACTION_TYPE.COMEBACK
			) {
				// 입실 문자 발송
				if (
					typeof student_info.phone == "string" &&
					student_info.phone.replace(/-/g, "")
				) {
					console.log("입실 문자 발송");
					SCHEDULE_API.send_sms_checkinout(
						student_info,
						"checkin",
						type_text
					);
				} else {
					console.log("부모님 번호 없음.");
				}
			}
		}
	},

	async action_checkout(student_info, date, type_text, send_message = true) {
		const student_no = student_info.no;
		if (date == undefined) date = new Date();

		// 퇴실 기록
		if (type_text === "외출") {
			const center_no = Number(student_info.center_no);
			if (center_no == 4 || center_no == 8 || center_no == 11) {
				// 임시 : 수내센터만 적용
				// 3시간 이상 외출 확인 로직.
				const is_long_term = await this.check_long_term_goout(
					student_no,
					date
				);
				if (is_long_term) {
					const req = {
						current_seat: null,
					};
					// 좌석 빼기.
					const res = await new ApiReq(
						"/student/" + student_info.id + "/info",
						req
					).put();
					console.log(res);
				}
			}
			await SCHEDULE_API.add_action_log(
				student_no,
				date,
				this.ACTION_TYPE.GOOUT
			);
		} else if (type_text === "퇴실") {

			await SCHEDULE_API.add_action_log(
				student_no,
				date,
				this.ACTION_TYPE.CHECKOUT
			);
		}

		if (
			send_message &&
			typeof student_info.phone == "string" &&
			student_info.phone.replace(/-/g, "")
		) {
			console.log("퇴실 문자 발송");
			SCHEDULE_API.send_sms_checkinout(
				student_info,
				"checkout",
				type_text
			);
		} else {
			console.log("부모님 번호 없음.");
		}
	},
	async check_long_term_goout(student_no, date) {
		if (date == undefined) date = new Date();

		const term = 3 * 60; // 3hour

		const schedule_data = await this.load_schedule(student_no);
		const weekindex = WEEKDAYS.GetTodayIndex(true);
		let current_minstamp = SPARTA_TIME.GetSpartaMinStampValue(date);
		// 24시간 지난 이후 판단.
		if (current_minstamp < (SPARTA_TIME.END_HOUR + 1) * 60) {
			current_minstamp += 24 * 60;
		}
		let is_long_term = false;
		let is_checked = false;
		try {
			const json_schedule = JSON.parse(schedule_data.json);
			const today_schedule_data = json_schedule[weekindex].sections;
			today_schedule_data.forEach((item, index) => {
				if (is_checked) {
					return;
				}
				const start_minstamp = item.stay.start.minstamp;
				if (start_minstamp > current_minstamp) {
					is_checked = true;
					if (index != 0) {
						const last_end_minstamp =
							today_schedule_data[index - 1].stay.end.minstamp;
						// 이전 끝나는 시간과 다음 시작 시간의 차이가 term 만큼 나는 경우 true
						if (last_end_minstamp + term <= start_minstamp) {
							is_long_term = true;
						}
					}
				}
			});
		} catch (e) {
			console.log(e);
		}
		return is_long_term;
	},
	async get_action_log(
		member_no,
		type = null,
		start_date = null,
		end_date = null
	) {
		let req = {
			student_no: member_no,
		};
		if (type) req.type = type;
		if (start_date)
			req.start = date_format(new Date(start_date), "YYYY-MM-DD");
		if (end_date) req.end = date_format(new Date(end_date), "YYYY-MM-DD");

		var res = await EXAPI.Call("get_action_log", req);

		if (res?.data) {
			res = res.data.data;

			// if(date && end_date){
			//     //TODO BackEnd에서 처리.
			//     const range_s = SPARTA_TIME.GetSpartaUtsRange(date).start;
			//     const range_e = SPARTA_TIME.GetSpartaUtsRange(end_date).end;
			//     console.log(range_s, range_e);
			//     res = res.filter(e => {
			//         const uts = Math.floor(new Date(e.f_action_date).getTime() / 1000);
			//         return range_s <= uts && uts < range_e;
			//     });
			// }else if(date){
			//     //TODO BackEnd에서 처리.
			//     const range = SPARTA_TIME.GetSpartaUtsRange();
			//     res = res.filter(e => {
			//         const uts = Math.floor(new Date(e.f_action_date).getTime() / 1000);
			//         return range.start <= uts && uts < range.end;
			//     });
			// }
			// res.sort((l, r) => new Date(r.f_action_date) - new Date(l.f_action_date));
			return res;
		} else return [];
	},
	// role_type == ROLE
	// member_status
	// TEACHER_TYPE / 0 재직, 9 퇴사, -1 삭제
	// STUDENT_TYPE / 0 상담, 1 대기, 2 재원, 3 쿠폰, 4 홀드, 9 퇴원, -1 삭제
	async get_center_action_log(
		center_no,
		action_type = null,
		role_type = null,
		member_status = null,
		start_date = null,
		end_date = null
	) {
		let req = {
			center_no,
			action_type,
			role_type,
			member_status,
		};
		if (start_date)
			req.start = date_format(new Date(start_date), "YYYY-MM-DD");
		if (end_date) req.end = date_format(new Date(end_date), "YYYY-MM-DD");

		let res = await EXAPI.Call("get_center_action_log", req);

		if (res?.data) {
			let data = res.data.data;
			let map = new Map();
			data.forEach((e) => {
				let {
					id,
					no,
					name,
					center_no,
					member_type,
					member_status,
					...action_log
				} = e;
				if (!map.has(id))
					map.set(id, {
						id,
						no,
						name,
						center_no,
						member_type,
						member_status,
						action_log: [],
					});
				const mapItem = map.get(id);
				if (action_log.f_no) mapItem.action_log.push(action_log);
			});
			return [...map.values()];
			//return res.data.data;
		} else return [];
	},
	async set_action_detail(action_no, detail) {
		let req = { action_no, detail };
		let res = await EXAPI.Call("set_action_detail", req);
		if (res?.data) return res.data.result;
		return false;
	},

	async teacher_add_action_log(member_no, type, date = new Date()) {
		const req = {
			student_no: member_no,
			action_type: type,
			uts: Math.floor(new Date(date).getTime() / 1000),
		};
		return (await EXAPI.Call("add_action_log", req))?.data;
	},
	async teacher_action_checkin() {
		const myinfo = (await ApiService.call(ApiService.GET, "/my/info"))
			?.data;
		if (!myinfo?.member_no) return;
		let ret_time;
		// let add_pin = false;
		const type = [
			this.ACTION_TYPE.CHECKIN,
			this.ACTION_TYPE.CHECKOUT,
		].join();
		var res = await this.get_action_log(myinfo.member_no, type, new Date());

		if (res?.length >= 0) {
			// 마지막에 찍힌 핀
			let last_pin = res?.[0];
			if (last_pin?.f_action_type == this.ACTION_TYPE.CHECKIN) {
				ret_time = date_format(last_pin.f_action_date, "hh:mm");
			}
			// if (!last_pin) {
			//     add_pin = true;
			// } else {
			//     switch (Number(last_pin.f_action_type)) {
			//         case this.ACTION_TYPE.CHECKIN:
			//             //이미 찍혀있으면 출근처리 완료.
			//             ret_time = date_format(last_pin.f_action_date, "hh:mm");
			//             break;
			//         case this.ACTION_TYPE.CHECKOUT:
			//             add_pin = true;
			//             break;
			//     }
			// }

			// if (add_pin) {
			//     res = await this.teacher_add_action_log(myinfo.member_no, this.ACTION_TYPE.CHECKIN);
			//     if (res?.result == "done") ret_time = date_format(new Date(), "hh:mm");
			// }
		}

		return ret_time;
	},
};

export { EXAPI, EXSETTING, SCHEDULE_API };
